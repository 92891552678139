import React, { useEffect, useState } from 'react';
import { useRefresh, } from "react-admin";
import {Grid, Paper, Box } from '@material-ui/core'
import ChatList from './ChatList';
import Chat from './Chat';
import Information from './Information';
import { Link, useLocation } from 'react-router-dom';

const Chats = () => {
  const [chat, setChat] = useState(null);
  const location = useLocation();
  const refresh = useRefresh();
  
  return (
    <Box height={900} style={{ overflow: 'hidden' }}>
      <Paper style={{ height: '100%' }}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={3} style={{ height: '100%' }}>
            <ChatList current={chat} setChat={setChat} />
          </Grid>
          <Grid item xs={6} style={{ height: '100%' }}><Chat chat={chat} refreshChatList={refresh}/></Grid>
          <Grid item xs={3} style={{ height: '100%' }}><Information chat={chat} /></Grid>
        </Grid>
      </Paper>
      
    </Box>
  )
}

const MessageList = (props) => {
  return (
    // <List sort={{ field: 'createdAt', order: 'DESC' }} {...props}>
      <Chats />
    // </List>
  )
}

export default MessageList;

