import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { 
  Loading, 
  useGetList, 
  fetchStart, 
  fetchEnd,
  useNotify,
  useRefresh,
  useVersion,
 } from 'react-admin';
import { 
  Paper, 
  Card, 
  CardContent, 
  CardHeader,
  Typography, 
  Box,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  makeStyles,
  createStyles,
} from '@material-ui/core/';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  PlayCircleOutline as PlayCircleOutlineIcon,
  Audiotrack as AudiotrackIcon
} from '@material-ui/icons';
import './Chat.css';
import SendMessageForm from './SendMessageForm';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const botBaseUrl = process.env.NODE_ENV === 'production' 
  ? 'https://question-telegram-bot.advaitac.com'
  // : 'http://test-telegram-bot.loca.lt';
  : 'http://localhost';

const useStyles = makeStyles((theme) => createStyles({

  chat: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '60%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 5,
    },
     
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'whitesmoke',
    },
     
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgray',
    }
  },
  
  messages: {
    display: 'flex',
    flexDirection: 'column-reverse',
    minHeight: 'min-content',
    padding: 10,
  },
  
  form: { 
    backgroundColor: theme.palette.grey[50],
    height: '40%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: 5,
    },
     
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'whitesmoke',
    },
     
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'lightgray',
    }
  }
}))

const attachmentStyle = {
  attachment: { 
    display: 'flex', 
    alignItems: 'center',
  },

  attachmentIcon: {
    marginRight: 10,
  }
}

const Chat = ({ chat, refreshChatList }) => {
  const styles = useStyles();
  const endOfMessageListRef  = useRef(null);
  const refresh = useRefresh();
  const version = useVersion();
  
  const { data, ids, loading } = useGetList(
    'messages',
    { page: 1, perPage: 1000 },
    { field: 'createdAt', order: 'DESC' },
    { userId: chat?.id },
    { enabled: !isEmpty(chat) }
  )

  useEffect(() => {
    if(endOfMessageListRef.current){
      endOfMessageListRef.current.scrollIntoView();
    }
  }, [endOfMessageListRef.current]);

  const markAllAsDone = () => {
    const url = `${botBaseUrl}/messages/chats/${chat.id}/done`;
    fetch(url, {
      method: 'post',
    })
      .then(() => {
        refreshChatList(true);
        document.location.reload()
      })
  }
  if (loading) return <Loading />
  if (!chat) return null;

  return (
    <Box height="100%">
      <Box className={styles.chat}>
        <Box className={styles.messages}>
          <div ref={endOfMessageListRef}></div>

          { ids.map((id) => <Message message={data[id]} />)}
        </Box>
      </Box>
      <Box className={styles.form}>
        <SendMessageForm record={chat} />
      </Box>
      {/* <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: 200, backgroundColor: 'slateblue' }}></div> */}
    </Box>
  )
}

export default Chat;

const initialState = {
  mouseX: null,
  mouseY: null,
};

const Message = ({ message }) => {
  const { text, messageData: { from }, attachment, createdAt, messageData } = message;
  const isDeletable = dayjs(createdAt).add(48, 'hours') > dayjs();
  const { message_id, chat } = messageData;
  const [state, setState] = useState(initialState);
  const isFocused = !!state.mouseY
  const [pending, setPending] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const deleteMessage = async () => {
    
    setPending(true);
    dispatch(fetchStart());

    await fetch(
      `${botBaseUrl}/messages/delete/`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json '
        },
        body: JSON.stringify({ 
          messageId: message_id, 
          chatId: chat.id 
        }),
      }
    );

    setPending(false);
    dispatch(fetchEnd());
    
    notify('Message successfuly deleted!');
    refresh();
  }

 

  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setState(initialState)
  }
  const handleDeleteCLick = () => {
    // alert('delete')
    deleteMessage()
    setState(initialState);
  };

  return (
    
    <Card 
      variant="outlined" 
      onContextMenu={handleClick} 
      style={{ maxWidth: '48%', ...( !from.is_bot && { alignSelf: 'flex-end' }), marginBottom: 10 }}
    >
      
      <CardContent>
        <Typography gutterBottom={true}>
          {text}
        </Typography>
        <Typography color="textSecondary" variant="caption" component="small">
          {createdAt}
        </Typography>
      </CardContent>

      <CardContent>
        {
          attachment && (
            <div>
              { message.attachment.mimetype === 'video/mp4' && (
                <div style={attachmentStyle.attachment}>
                  <PlayCircleOutlineIcon style={attachmentStyle.attachmentIcon} />
                  <div>
                    {message.attachment.originalname}
                  </div>
                </div>
              ) }

              { message.attachment.mimetype === 'audio/mpeg' && (
                <div style={attachmentStyle.attachment}>
                  <AudiotrackIcon style={attachmentStyle.attachmentIcon} />
                  <span>{message.attachment.originalname}</span>
                </div>
              )}
            </div>
          )
        }
      </CardContent>
      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleDeleteCLick} disabled={!isDeletable}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>  
          Удалить
        </MenuItem>
      </Menu>
    </Card> 
  )
}