import * as React from "react";
import { 
  List, 
  TextField, 
  DateField, 
  TextInput,
  SimpleForm,
  Create,
  ChipField,
  Datagrid,
} from 'react-admin';

const ColoredChipField = (props) => {
  const isCompleted = props.record.state?.value === 'completed';
  const color = isCompleted ? 'primary' : 'default';
  
  return <ChipField source="state.value" color={color} />;
}

export const SendingList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="plannedAt" />
        <TextField source="message" />
        <ColoredChipField />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
    </Datagrid>
  </List>
);


export const SendingCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput 
            source="message" 
            fullWidth 
            multiline 
            rows={5}
            rowsMax={10}
          />
      </SimpleForm>
  </Create>
);