import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import { 
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
  Badge,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core';
import { truncate } from 'lodash';
import { FixedSizeList } from 'react-window';
import { Loading } from 'ra-ui-materialui';
import MailIcon from '@material-ui/icons/Mail';
import MessageIcon from '@material-ui/icons/Message';
import './ChatList.css';
import { useVersion, useRefresh, LinearProgress } from 'react-admin';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const CHAT_LIST_URL = process.env.NODE_ENV === 'production' 
  ? 'https://question-telegram-bot.advaitac.com/messages/chats' 
  : 'http://localhost/messages/chats';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRightWidth: 1, 
    borderRightColor: 'lightgray', 
    borderRightStyle: 'solid',
  },
  inline: {
    display: 'inline',
  },
}));

const initialState = {
  mouseX: null,
  mouseY: null,
};


const botBaseUrl = process.env.NODE_ENV === 'production' 
  ? 'https://question-telegram-bot.advaitac.com'
  // : 'http://test-telegram-bot.loca.lt';
  : 'http://localhost';


function Chat({ index, style, data: { chats, setChat, current, refresh } }) {
  const classes = useStyles();

  const user = chats[index]
  const { username, firstName, lastName, Messages: messages, Messages: [ message ] } = user;
  const isCurrent = user.id === current?.id;
  const [state, setState] = useState(initialState);
  
  const handleClick = (event) => {
    event.preventDefault();
    setState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const markAllAsDone = () => {
    const url = `${botBaseUrl}/messages/chats/${user.id}/done`;
    fetch(url, {
      method: 'post',
    })
      .then(() => {
        setState(initialState)
        message.isViewed = true;
        refresh();
      })
  }

  const handleClose = () => {
    setState(initialState)
  }

  return(
    <ListItem 
      onContextMenu={handleClick}
      key={index} 
      alignItems="flex-start" 
      onClick={() => setChat(user)} 
      style={
        { cursor: 'pointer' ,
        ...( isCurrent && { 
          backgroundColor: 'lightgray' ,
        }),
        ...style
        }
      }
    >
      <ListItemAvatar>
        <Avatar alt={lastName} src="/static/images/avatar/1.jpg" />
      </ListItemAvatar>

      <ListItemText
        primary={`${firstName} ${lastName} (${username})`}
        secondary={
          <React.Fragment>
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="textPrimary"
            >
              {message.createdAt}
            </Typography>
            <Typography>

              {truncate(message.text, { length: 50, omission: ' [...]'})}
            </Typography>
             

          </React.Fragment>
        }
      />
      { !message.isViewed && (
        <Badge badgeContent={messages.length} color="secondary" style={{ top: 13 }}>
          <MessageIcon />
        </Badge>
      )} 

      <Menu
        keepMounted
        open={state.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          state.mouseY !== null && state.mouseX !== null
            ? { top: state.mouseY, left: state.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={markAllAsDone} disabled={false}>
          <ListItemIcon>
            <DoneAllIcon fontSize="small" />
          </ListItemIcon>  
          Отметить как прочитанный
        </MenuItem>
      </Menu>
    </ListItem>
  )
}

export default function ChatList({ setChat, current }) {


  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const version = useVersion();
  const refresh = useRefresh();

  useEffect(() => {
    setLoading(true);
    fetch(CHAT_LIST_URL)
      .then((response) => response.json())
      .then((response) => {
        setChats(response);
        setLoading(false)
      })  
  }, []);

  if (loading) return <Loading />;

  return (
    <div key={version}> 

      <FixedSizeList 
        
        itemData={{chats, setChat, current, refresh }} 
        itemCount={chats.length}
        height={900}
        itemSize={110}
        width="100%"
        onClick={setChat}
        className="ChatList"
      >
        {Chat}
      </FixedSizeList>
    </div>
  );
}

// const { data: messages, ids: messagesIds, loading: loadingMessages } = useGetList('messages', 
  //   { page: 1, perPage: 100 },
  //   { field: 'createdAt', order: 'DESC' }
  // )

  
  // const ids = uniq(messagesIds.map((id) => messages[id].userId));

  // console.log(ids)
  // const { data, loading: loadingUsers } = useGetMany(
  //   'users', 
  //   ids, 
  //   { enabled: messagesIds.length > 0 }
  // );

  // console.log(loadingMessages, loadingUsers);
