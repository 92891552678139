function extractName (from) {
  const { username, first_name, last_name, id } = from;

  if(username) return `@${username}`;

  if(first_name || last_name) return `${first_name} ${last_name}`;

  return id;
}

module.exports = {
  extractName
}