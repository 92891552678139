import { useState, useEffect } from "react";
import { useAuthProvider, useGetList } from 'react-admin';

function useIsFreeQuestionExecutor () {

  const auth = useAuthProvider();
  const [authUser, setAuthUser] = useState(null)
  // const [defaultExecutor, setDefaultExecutor] = useState(null);

  const { data, loading } = useGetList(
    'executors',
    { page: 1, perPage: 10 },
  );

  useEffect(() => {
    auth.getAuthUser().then((user) => {
      setAuthUser(user)
    })
  }, [auth])

  if (!authUser) return true;

  // useEffect(() => {
  //   if (authUser) {
  //     const item = Object.entries(data)
  //       .map(([, executor]) => executor)
  //       .find((executor) => executor.email.toLowerCase() === authUser.email.toLowerCase())
  //     setDefaultExecutor(item);
  //   }
  // }, [data, loading, authUser])

  const isExecutorDisabled = authUser &&  ['radionova108@gmail.com',  'kray10@ngs.ru', 'klimchuk108@gmail.com'].includes(authUser.email.toLowerCase()); 

  return isExecutorDisabled;
}

export default useIsFreeQuestionExecutor;
