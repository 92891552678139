import { 
  Chip, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Typography, 
  Avatar,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { LinearProgress, useRedirect, useGetList, useGetMany } from 'react-admin';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FixedSizeList } from 'react-window';
import { format, differenceInDays, parseISO } from 'date-fns';
import plural from 'plural-ru';
import ru from 'date-fns/locale/ru';
import { find, uniq } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 400,
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: 20,
  },
}));

function ExpiredQuestion(props) {
  const { index, style, data: { questions, executors } } = props;
  const question = questions[index];
  const { completeIn, name, order, executorId } = question;
  const days = differenceInDays(new Date(), completeIn);
  const redirect = useRedirect();

  console.log(executors)
  return (
    <ListItem button style={style} key={index} onClick={() => redirect('show', '/questions', question.id)}>
      <ListItemAvatar>
        <Avatar>
          {question.name.split('')[0]}
        </Avatar>
      </ListItemAvatar>
      <ListItemText 
        primary={format(parseISO(completeIn), 'dd MMMM (dddd) yyyy', { locale: ru })} 
        secondary={name}
      />
      <Typography style={{ paddingRight: 10 }}>{find(executors, e => e.id === executorId).name}</Typography>
      <Typography>
        {
          order && (
              <Chip label={order.total} color="primary" avatar={<Avatar>&#8381;</Avatar>}></Chip>
          )
        }
        <Typography variant="caption" style={{ marginLeft: 10 }}>{days} {plural(days, 'день', 'дня', 'дней')}</Typography>
        
        
      </Typography>
      
    </ListItem>
  );
}

ExpiredQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired,
};
 
const ExpiredQuestions = () => {
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  // const { data, loaded } = useGetList(
  //   'executors',
  //   { page: 1, perPage: 100 },
  //   null,
  //   null,
  //   { enabled: true }

  // );

  const { data: executors, isLoadingExecutors } = useGetMany(
    'executors',
    uniq(questions.map(q => q.executorId)),
    { enabled: questions.length > 0}
  )

  const classes = useStyles();
  const url = process.env.NODE_ENV === 'production'
    ? 'https://question-telegram-bot.advaitac.com/questions/expired'
    : 'https://question-telegram-bot.advaitac.com/questions/expired'
    // : 'http://localhost/questions/expired';

  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then(response => response.json())
      .then((data => {
        setQuestions(data);
        setLoading(false);
      }))
  }, [])

  if (loading && isLoadingExecutors) return <LinearProgress />
  
  return (
    <>
      <Typography variant="h4" paragraph>Истекшие вопросы ({questions.length})</Typography>
      <div className={classes.root}>
        
        <FixedSizeList
          itemData={{
            questions,
            executors,
          }}
          height={400} 
          width="100%" 
          itemSize={60} 
          itemCount={questions.length} 
          className="custom-scroll"
        >
          {ExpiredQuestion}
        </FixedSizeList>
      </div>
    </>

  )
}

export default ExpiredQuestions;

