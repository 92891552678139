import * as React from "react";
import simpleRestProvider from 'ra-data-simple-rest';
import { Admin, Resource, EditGuesser, ShowGuesser, ListGuesser, Create } from 'react-admin'
import { UserList } from "./users";
import { QuestionCreate, QuestionEdit, QuestionList, QuestionShow } from "./questions";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import MailIcon from '@material-ui/icons/Mail';
import MessageIcon from '@material-ui/icons/Message';
import UserIcon from '@material-ui/icons/Group';
import Dashboard from "./dashboard/dashboard";
import { UserMessages, UserShow } from "./messages";
import {
  FirebaseAuthProvider,
} from 'react-admin-firebase';
import { SendingCreate, SendingList } from "./sendings";
import { ExecutorCreate, ExecutorList } from "./executors";
import MessageList from "./messages/MessageList";
import { Route } from 'react-router-dom';
import './App.css';
import Analytics from "./Analytics";
import { Layout } from "./Layout";

var firebaseConfig = {
  apiKey: "AIzaSyA2CV5GYGLwshNlTJhBbIs5IUj_GiKwaVI",
  authDomain: "question-bot-8f58f.firebaseapp.com",
  projectId: "question-bot-8f58f",
  storageBucket: "question-bot-8f58f.appspot.com",
  messagingSenderId: "72541457373",
  appId: "1:72541457373:web:38ae7b424314e77a5c0434",
  measurementId: "G-VPETKE7CYG"
};

const authProvider = FirebaseAuthProvider(firebaseConfig);

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const dataProvider = process.env.NODE_ENV === 'production' 
  ? simpleRestProvider('https://question-telegram-bot.advaitac.com/api')
  // : simpleRestProvider('http://localhost/api');
  : simpleRestProvider('https://question-telegram-bot.advaitac.com/api')

const App = () => (
    <Admin
      layout={Layout}
      customRoutes={[
        <Route exact path="/analytics" component={Analytics } />
      ]}
      dataProvider={dataProvider} 
      authProvider={authProvider}
      i18nProvider={i18nProvider} 
      // dashboard={Dashboard}
    >
      <Resource name="questions" list={QuestionList} edit={QuestionEdit} create={QuestionCreate} icon={QuestionAnswerIcon} show={QuestionShow}/>
      <Resource name="users" list={UserList} edit={EditGuesser} icon={UserIcon} show={UserShow} />
      <Resource name="sendings" list={SendingList}  create={SendingCreate} icon={MailIcon} />
      <Resource name="executors" list={ExecutorList} create={ExecutorCreate} edit={EditGuesser} />
      <Resource name="messages" list={MessageList} icon={MessageIcon} />
    </Admin>
);
export default App;
