import { useState, useEffect } from 'react'
import { Loading, useGetList } from 'react-admin';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead, 
  TableFooter,
  Paper,
} from '@material-ui/core';

const ReturningUsers = () => {

  const { loading, ids, loaded, data } = useGetList(
    'users',
    { page: 1, perPage: 1000 },
    { field: 'paidQuestionsCount', order: 'DESC' },
    { 
      paidQuestionsCount: 2,
    },
    { }
  )

  if (loading) return <Loading />

  console.log(data)

  return (
    <TableContainer component={Paper} style={{ marginTop: 50 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Пользователь</TableCell>
            <TableCell>Имя</TableCell>
            <TableCell>Вопросов</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { ids.map((id) => {
            const { username, firstName, lastName, paidQuestionsCount } = data[id];

            return (
              <TableRow>
                <TableCell>
                  {username}
                </TableCell>
                
                <TableCell>
                  { [firstName, lastName].join(' ') }
                </TableCell>
                <TableCell>
                  {paidQuestionsCount}
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default ReturningUsers;


/* 

  1. add paidQuestionCounter column to User
  2. add afterCreate and afterDestroy hooks to increment/decrement counter
  3. implement component with useGetList

*/