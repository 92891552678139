import * as React from "react";
import { 
  List, 
  TextField, 
  DateField, 
  TextInput,
  SimpleForm,
  Create,
  Datagrid,
  BooleanField,
} from 'react-admin';


export const ExecutorList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="email" />
        <BooleanField source="isFreeQuestionExecutor" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
    </Datagrid>
  </List>
);


export const ExecutorCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" />
          <TextInput source="email" />
      </SimpleForm>
  </Create>
);