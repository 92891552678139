import * as React from 'react';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink, getResources } from 'react-admin';
import DefaultIcon from '@material-ui/icons/ViewList';
import LabelIcon from '@material-ui/icons/Label';
import AssessmentIcon from '@material-ui/icons/Assessment';
import useIsFreeQuestionExecutor from './useIsFreeQuestionExecutor';
import { usePermissions } from 'react-admin';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const Menu = () => {
    const isFree = useIsFreeQuestionExecutor();
    const premissions = usePermissions();
    
    console.log('PREMISSIONS', premissions);

    const resources = useSelector(getResources);
    return (
        <div style={{ marginTop: 20 }}>
            <DashboardMenuItem />
            {resources.map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={
                        (resource.options && resource.options.label) ||
                        capitalizeFirstLetter(resource.name)
                    }
                    leftIcon={
                        resource.icon ? <resource.icon /> : <DefaultIcon />
                    }
                />
            ))}
            {
                !isFree && (
                    <MenuItemLink 
                      key="analytics"
                      to="/analytics"
                      primaryText="Аналитика"
                      leftIcon={<AssessmentIcon/>}
                    />
                )
            }
        </div>
    );
};