import { 
  useShowController, 
  SimpleShowLayout, 
  useNotify, 
  useGetManyReference, 
  Button, 
  useRefresh, 
  fetchStart, 
  fetchEnd,
} from "react-admin";
import { useDispatch } from 'react-redux';
import React, { useState, useCallback } from "react";
import clsx from 'clsx';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardActionArea,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography, 
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Form, Field } from 'react-final-form';
import dayjs from 'dayjs'; 
import Dropzone from 'react-dropzone';
import accept from 'attr-accept';

const botBaseUrl = process.env.NODE_ENV === 'production' 
  ? 'https://question-telegram-bot.advaitac.com'
  // : 'http://test-telegram-bot.loca.lt';
  : 'http://localhost';

const initialValues = {
  messageText: '',
  attachment: [],
}
  
const SendMessageForm = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [isSendConfiramtionDialogOpen, setIsSendConfiramtionDialogOpen] = useState(false);

  return (
    <Box style={{ padding: 20, marginBottom: 20 }}>
      
      <Form
        initialValues={initialValues}
        onSubmit={async (values) => {

          const formData = new FormData();

          formData.append('telegramUserId', record.telegramUser);
          formData.append('telegramChatId', record.telegramUser);
          formData.append('text', values.messageText);
          
          if (values.attachment.length) {
            const attachment = values.attachment[0];
            formData.append('attachment', attachment);
            formData.append('attachment_name', attachment.name);
          }          

          setPending(true);
          dispatch(fetchStart());

          await fetch(
            `${botBaseUrl}/messages/send/`,
            {
              method: 'post',
              body: formData,
            }
          );

          dispatch(fetchEnd());
          setPending(false);
          notify('Message successfuly created!');
          refresh();

        }}
        
        validate={(values) => {
          const errors = {};
          
          if (!values.attachment[0] && values.messageText?.length < 3) {
            errors.messageText = 'Минимальная длина сообщения 3 символа';
          }

          return errors;
        }}

        render={({ handleSubmit, valid, pristine, reset, form }) => (
          <form encType="multipart/form-data">
            <Field name="messageText">
              {({ input, meta }) => (
                <div>
                  <TextField 
                    fullWidth 
                    multiline 
                    rows={4}
                    rowsMax={10}
                    label="Сообщение"
                    helperText="Текст сообщения, который будет отправлено в телеграм."
                    type="text" 
                    placeholder="Набирайте текст вашего сообщения.." 
                    style={{ marginBottom: 20 }}
                    {...input} 
                  />
                  {meta.touched && meta.error && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="attachment">
              {({ input }) => (
                <div>
                  <Dropzone
                    accept={[ 'video/mp4', 'audio/*', 'image/jpeg', 'image/png' ]}
                    multiple={false}
                    onDrop={(files) => {
                      form.change('attachment', files.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                      })))
                    }}

                    onDropRejected={(files) => {
                      console.log('rejected')
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <>
                        <div {...getRootProps()} style={{ border: '2px dashed gray', padding: 5, marginTop: 10, marginBottom: 10 }}>
                          <input {...getInputProps()} />
                          <p>Перетащите фаил сюда или кликните, чтобы выбрать с диска.</p>
                        </div>
                        
                        { input.value[0] && <FilePreview file={input.value[0]} />}
                        
                      </>
                    )}
                  </Dropzone>
                </div>
              )}
            </Field>

            <Button 
              type="submit" 
              disabled={!valid || pristine || pending }
              onClick={(event) => {
                event.preventDefault();
                setIsSendConfiramtionDialogOpen(true);
              }}
            ><span>Отправить</span></Button>
            
            <Dialog open={isSendConfiramtionDialogOpen}>
              <DialogTitle>Send message</DialogTitle>
              <DialogContent>
                { pending && <CircularProgress size={40} style={{ marginBottom: 20 }} />}
                <DialogContentText>Вы действительно хотите отправить сообщение?</DialogContentText>
              </DialogContent>

              <DialogActions >
                <Button
                  disabled={pending} 
                  onClick={() => setIsSendConfiramtionDialogOpen(false)}><span>No</span></Button>
                <Button 
                  disabled={pending}
                  onClick={async (event) => {
                    event.preventDefault()
                    await handleSubmit(event)
                    form.reset();
                    setIsSendConfiramtionDialogOpen(false);
                }}><span>Yes</span></Button>
              </DialogActions>
            </Dialog>
          </form>

        )}
      />

      
    </Box>
  )
}

export default SendMessageForm;

const FilePreview = ({ file }) => {
  const {preview, name} = file;
  let Preview;

  if (accept(file, 'image/*')) {
    Preview = <img src={preview} style={{ maxWidth: 200, maxHeight: 200 }} alt={name} />;
  } else if (accept(file, ['video/*', 'audio/*'])) {
    let MediaTag;
    
    if (accept(file, 'video/*')) MediaTag = 'video';
    if (accept(file, 'audio/*')) MediaTag = 'audio';
    
    Preview = (
      <MediaTag controls width={200}>
        <source src={preview}></source>
      </MediaTag>
    )
  } else {
    Preview = <p>{name}</p>;
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      {Preview}
    </div>
  )
}