import { useState, useEffect } from "react";
import { 
  List, 
  TextField, 
  NumberField, 
  ReferenceField, 
  DateField, 
  EditButton,
  TextInput,
  SimpleForm,
  Edit,
  Create,
  Filter,
  ReferenceInput,
  SelectInput,
  ShowButton,
  SimpleShowLayout,
  Show,
  TopToolbar,
  Button,
  useUpdate,
  ChipField,
  useListContext,
  BooleanInput,
  DateInput,
  DateTimeInput,
  AutocompleteArrayInput,
  useRecordContext,
  Labeled,
  useGetList,
  useAuthProvider,
  Pagination,
  Loading,
  NullableBooleanInput,
} from 'react-admin';
import {
  Chip,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
} from '@material-ui/core';
import { groupBy } from "lodash";
import dayjs from "dayjs";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneIcon from '@material-ui/icons/Done';
import { format, parseISO, startOfDay } from 'date-fns';
import ru from 'date-fns/locale/ru';
import ExpiredQuestions from "./questions/ExpiredQuestions";

const dateFormater = date => dayjs(date).format('YYYY-MM-DD')

const QuestionFilter = (props) => {
  return (
  <Filter {...props}>
    {/* <TextInput source="q" label="Поиск" alwaysOn /> */}
    <DateInput 
      label="Дата завершения >=" 
      source="completeIn" 
      alwaysOn 
    />

    <SelectInput 
      source="state"
      alwaysOn
      choices={[
        { id: 'pending', name: 'В обработке' },
        { id: 'complete', name: 'Завершен' },
      ]} 
    />

    <ReferenceInput 
      alwaysOn 
      source="executorId" 
      reference="executors" 
      disabled={props.isExecutorDisabled}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>

    <NullableBooleanInput
      label="Оплачен"
      source="isPaid"
      alwaysOn
      nullLabel="Все"
    />

  </Filter>
)};

export const QuestionList = props => {

  const auth = useAuthProvider();
  const [authUser, setAuthUser] = useState(null)
  const [defaultExecutor, setDefaultExecutor] = useState(null);

  const { data, loading } = useGetList(
    'executors',
    { page: 1, perPage: 10 },
  );

  useEffect(() => {
    auth.getAuthUser().then((user) => {
      setAuthUser(user)
    })
  }, [auth])


  useEffect(() => {
    if (authUser) {
      const item = Object.entries(data)
        .map(([, executor]) => executor)
        .find((executor) => executor.email.toLowerCase() === authUser.email.toLowerCase())
      setDefaultExecutor(item);
    }
  }, [data, loading, authUser])


  const isExecutorDisabled = authUser &&  ['radionova108@gmail.com',  'kray10@ngs.ru', 'klimchuk108@gmail.com'].includes(authUser.email.toLowerCase()); 

  return (
    <>
    <ExpiredQuestions />
    <List 
      filters={<QuestionFilter isExecutorDisabled={isExecutorDisabled} />}
      filterDefaultValues={{ 
        completeIn: format(startOfDay(new Date()), 'yyyy-MM-dd'),
        state: 'pending',
        ...(defaultExecutor && {executorId: defaultExecutor.id })
      }}
      sort={{ field: 'completeIn', order: 'ASC' }}
      {...props}>
        <GroupedQuestionList />
    </List>
    </>
  )
};

const styles = {
  questionLabel: { 
    fontWeight: 'bold', 
    marginBottom: 10 
  }, 
}

const getRatingEmoji = (rating) => {
  switch (rating) {
    case 1: return '😠'; 
    case 2: return '🙁'; 
    case 3: return '😐'; 
    case 4: return '😃'; 
    case 5: return '🤩'; 
    default: return null;
  }
}

const GroupedQuestionList = (props) => {
  const { ids, data, basePath, loading } = useListContext();
  const cardStyle = {
    margin: '0.5em',
    verticalAlign: 'top'
  };

  if (loading) return <Loading />;

  const groups = groupBy(ids, id => {
    if(!data[id]) return data[id];
    let d = data[id].completeIn;
    // console.log(d);
    d = parseISO(d);
    // console.log(d);
    d = startOfDay(d);
    // console.log(d); 
    return d;
    // d = parseISO(d);
    console.log(d);
    const date = parseISO(startOfDay(data[id].completeIn));
    return date;
  });


  return (
      <div style={{ margin: 40 }}>
      {
        Object.entries(groups).map(([date, groupIds]) => (
          <div className="flex">
            <Typography variant="h3" gutterBottom={true}>
              {/* {format(parseISO(date), 'DD MMMM (dddd) YYYY', { locale: ru })} */}
              {date}
            </Typography>
            <div style={{ paddingLeft: 50 }}>
              {
                groupIds.map(id => {
                  const item = data[id];
                  const isComplete = item.state === 'complete';
                  const chipStyle = isComplete ? { 
                    marginRight: 10,
                    backgroundColor: isComplete ? 'green' : 'light-gray',
                    color: 'white',
                  } : {
                    marginRight: 10,
                    backgroundColor: 'light-gray',
                  };
                  
                  return (
                  <Card key={id} style={cardStyle}>
                      <CardHeader
                          title={item.name}
                          subheader={<DateField record={data[id]} source="createdAt" locales="ru-RU" options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }} />}
                          // avatar={<Avatar icon={<PersonIcon />} />}
                      />
                      
                      <CardContent>
                        <Typography paragraph>
                          Дата создания:
                          {' '}
                          {format(parseISO(data[id].createdAt), 'dd-MM-yyyy')}
                        </Typography>

                        <Typography paragraph>
                          Дата завершения: 
                          {' '}
                          {format(parseISO(data[id].completeIn), 'dd-MM-yyyy')}
                        </Typography>

                        { item.order && (
                          <Typography>
                            Дата оплаты:
                            {' '}
                            {data[id].order?.date_paid?.date}
                          </Typography>
                        )}
                      </CardContent>
                      <CardContent>
                        <div style={styles.questionLabel}>Вопрос:</div>
                        {item.question}
                      </CardContent>

                      { item.additionalQuestion && (
                        <CardContent>
                          <div style={styles.questionLabel}>Дополнительный вопрос:</div>
                          {item.additionalQuestion} 
                        </CardContent>
                      )}

                      { item.rating && (
                        <>
                          <CardContent>
                            <div>Пользователь оценил ответ на: {getRatingEmoji(item.rating)} ({item.rating}) </div>
                          </CardContent>

                          <CardContent>
                            <Typography>{item.ratingComment}</Typography>
                          </CardContent>
                        </>
                      )}

                      <CardContent>
                        <div style={{ display: "flex" }}>
                          <Chip
                            icon={ isComplete ? <DoneIcon /> : <HourglassEmptyIcon />}
                            label={ isComplete ? 'Отвечен' : 'Ожидает ответа' }
                            style={chipStyle}
                          />
                          { data[id].order && (
                              <>
                          
                                <Chip
                                  avatar={<Avatar>&#8381;</Avatar>}
                                  label={`${data[id].order.total}`}
                                  color="primary"
                                />
                              </>
                            )
                          }
                        </div>
                      </CardContent>
                      
                      <CardContent>
                          
                          <div>
                            Пользователь:&nbsp;
                            <ReferenceField label="Users" resource="users" record={data[id]} source="userId" reference="users" link="show" >
                                <TextField source="from.id" />
                            </ReferenceField>
                          </div>
                      </CardContent>

                      <CardContent>
                          
                          <div>
                            Исполнитель:&nbsp;
                            <ReferenceField label="Executors" resource="executors" record={data[id]} source="executorId" reference="executors" link="show" >
                                <TextField source="name" />
                            </ReferenceField>
                          </div>
                      </CardContent>
                      <CardActions style={{ textAlign: 'right' }}>
                          <ShowButton resource="questions" basePath={basePath} record={data[id]} />
                          <EditButton resource="questions" basePath={basePath} record={data[id]} />
                      </CardActions>
                  </Card>
                )}
              )
              }
            </div>
          </div>
        ))
      }
      </div>
  );
};

const ToggleStateButton = ({record}) => {
  const state = record.state === 'complete' ? 'pending' : 'complete';
  const [toggleState, { loading, error }] = useUpdate('questions', record.id, { state }, record);

  return  state === 'complete' ? (
    <Button 
      color="primary" 
      onClick={toggleState}
      disabled={loading}
    >
      <span>Завершить</span>
    </Button>
  ) : (
    <Button 
      color="primary" 
      onClick={toggleState}
      disabled={loading}
    >
      <span>Отложить</span>
    </Button>
  )
   
}

const QuestionShowActions = ({ basePath, data, resource }) => {
  
  if(!data) return null;

  return (
    <TopToolbar>
      <ToggleStateButton record={data}/>
    </TopToolbar>
  )
};

const RatingField = (props) => {
  const { source } = props;
  const record = useRecordContext(props);
  return (
    <div>
      <Labeled source={source} />
      <div>{getRatingEmoji(record[source])} ({record[source]})</div>
    </div>
  )
}

export const QuestionShow = props => (
  <Show actions={<QuestionShowActions />} {...props}>
      <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="birthdate" />
          <TextField source="birthtime" />
          <TextField source="country" />
          <TextField source="city" />
          <TextField source="question" />
          <TextField source="additionalQuestion" />
          <TextField source="state" />
          <RatingField source="rating" />
          <ReferenceField source="userId" reference="users" link="show"><TextField source="from.id" /></ReferenceField>
          <DateField source="createdAt" locales="ru-RU" />
          <DateField source="updatedAt" locales="ru-RU" />
      </SimpleShowLayout>
  </Show>
);


export const QuestionCreate = props => (
  <Create {...props}>
      <SimpleForm>
          <TextInput source="name" />
          <TextInput source="birthdate" />
          <TextInput source="birthtime" />
          <TextInput source="country" />
          <TextInput source="city" />
          <TextInput source="question" />
          <TextInput source="state" />
          <ReferenceInput source="userId" reference="users"><SelectInput optionText="username" /></ReferenceInput>
      </SimpleForm>
  </Create>
);

export const QuestionEdit = props => (
  <Edit {...props}>
      <SimpleForm>
          <TextInput source="name" />
          <TextInput source="birthdate" />
          <TextInput source="birthtime" />
          <TextInput source="country" />
          <TextInput source="city" />
          <TextInput source="question" />
          <TextInput source="state" />
          <ReferenceInput source="executorId" reference="executors">
            <SelectInput optionText="name"/>
          </ReferenceInput>
          <ReferenceInput source="userId" reference="users">
            <SelectInput optionText="username" />
          </ReferenceInput>
      </SimpleForm>
  </Edit>
);

