import React from 'react';
import { isEmpty } from "lodash";
import { Loading, useGetList } from "react-admin";
import { makeStyles } from '@material-ui/core/styles';
import { format, parseISO } from 'date-fns';
import clsx from 'clsx';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Chip, 
  Box,
  Card, 
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography, 
} from '@material-ui/core/';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import './Information.css';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    marginBottom: 10,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));


const Information = ({ chat }) => {
  const { data, ids, loading} = useGetList(
    'questions', 
    { page: 1, perPage: 100 }, 
    { field: 'createdAt', order: 'DESC'},
    { userId: chat?.id },
    { enabled: !isEmpty(chat) }
  );

  if (loading) return <Loading />;
  
  return (
    <Box className="Information" height={'100%'} style={{ overflowY: 'scroll' }}>
      { ids.map((id) => <QuestionReviewCard question={data[id]} />)}
    </Box>
  )
}

export default Information;

function QuestionReviewCard({ question }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { 
    completeIn, 
    question: firstQuestion, 
    additionalQuestion, 
    price,
    name,
    birthdate,
    birthtime,
    city,
    country,
    createdAt,
    order,
  } = question;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {name.split('')[0]}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={`${name}`}
        subheader={completeIn}
      />
      
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          { firstQuestion }
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          { additionalQuestion }
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" style={{ paddingTop: 10 }}>
          <Chip label={price} color={ order ? "primary" : "default" }/>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites"
          component={Link}
          target="_blank"
          to={{
            pathname: `/questions/${question.id}/show`,
          }}
        >
          <OpenInNewIcon />
        </IconButton>
        
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <List>
            <ListItem>
              <ListItemText primary="Дата рождения" secondary={birthdate}></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText primary="Время рождения" secondary={birthtime}></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText primary="Страна, Город" secondary={`${country}, ${city}`}></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText primary="Вопрос" secondary={firstQuestion}></ListItemText>
            </ListItem>

            { additionalQuestion && (
              <ListItem>
                <ListItemText primary="Дополнительный" secondary={additionalQuestion}></ListItemText>
              </ListItem>
            )}

            <ListItem>
              <ListItemText primary="Дата создания" secondary={format(parseISO(createdAt), 'dd-MM-yyyy')}></ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText primary="Дата завершения" secondary={format(parseISO(completeIn), 'dd-MM-yyyy')}></ListItemText>
            </ListItem>
          </List>
        </CardContent>
      </Collapse>
    </Card>
  );
}