import * as React from "react";
import { List, Datagrid, TextField, EmailField, DateField, EditButton, ShowButton, DeleteButton, CloneButton, DeleteWithConfirmButton } from 'react-admin';

export const UserList = props => (
  <List {...props}>
      <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="username" />
          <DateField source="createzdAt" />
          <DateField source="updatedAt" />
          
          <EditButton />
          <ShowButton />
          <DeleteWithConfirmButton />
      </Datagrid>
  </List>
);