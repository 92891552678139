import { 
  Box, 
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead, 
  TableFooter,
  Button,
  ButtonGroup,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  makeStyles,
} from '@material-ui/core';
import { useGetList, LinearProgress, Tab, Loading, useRefresh } from 'react-admin';
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  Line, 
  Area,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer,
} from "recharts";
import { groupBy, isEmpty, orderBy, reduce, set, sum, sumBy } from 'lodash';
import { 
  startOfDay, 
  format, 
  startOfWeek, 
  startOfMonth, 
  startOfYear, 
  endOfYear, 
  parseISO, 
  formatISO, 
  startOfQuarter, 
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfQuarter,
  isEqual,
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import ReturningUsers from './analytics/ReturningUsers';

const TAX = 3.9;

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function getPeriodFuncs(period = 'month') {
  switch(period) {
    case 'day': return [startOfDay, endOfDay];
    case 'week': return [startOfWeek, endOfWeek];
    case 'month': return [startOfMonth, endOfMonth];
    case 'quarter': return [startOfQuarter, endOfQuarter];
    case 'year': return [startOfYear, endOfYear];
    default: return [startOfMonth, endOfMonth];
  }
}

const prepareData = (questions, period) => {

  const [periodFunc] = getPeriodFuncs(period);
  const groups = groupBy(questions, (q) => {
    return format(
      periodFunc(parseISO(q.createdAt)), 
      'yyyy-MM-dd'
    )
  });

  console.log(groups)

  return Object.entries(groups).map(([day, questions], index) => {
    console.log(day, typeof day)
    return {
    date: day,//format(day, 'YYYY-MM-DD'),
    count: questions.length,
    paidCount: questions.filter((q) => !!q.order).length,
    sum: reduce(questions.filter((q) => !!q.order), (sum, q) => { return sum = sum + (+q.order.total)}, 0)
  }})
};

const prepareTableData  = (questions) => {
  const groups = groupBy(questions, (q) => q.price)

  return Object.entries(groups).map(([price, items]) => {
    const sum = reduce(items, (sum, q) => sum = sum + (q.order ? (+q.order.total) : 0), 0)
    return {
      price,
      total: items.length,
      complete: items.filter((q) => q.state === 'complete').length,
      sum,
      sumWithoutTax: sum - sum / 100 * TAX,
      tax: sum / 100 * TAX,
    }
})
}

const priceLabel = (price) => {
  if (price === 'price-100') return 'Бесплатные';
  const match = price.match(/\d+/);
  if (match) return `За ${match[0]}`
  return 'Неизвостно'
}

const Analytics = () => {

  const classes = useStyles();
  const now = new Date();
  const [startDate, setStartDate ] = useState(startOfYear(now));
  const [endDate, setEndDate] = useState(endOfYear(now))
  const [period, setPeriod] = useState('day');
  const refresh = useRefresh();

  const setDateRangeTo = (range = "month") => {
    const [start, end] = getPeriodFuncs(range);
    setStartDate(start(now));
    setEndDate(end(now));
  }

  const { data, ids, loading, refetch } = useGetList(
    'questions',
    { page: 1, perPage: 1000 },
    { field: 'completeIn', order: 'asc' },
    { 
      createdAt: [
        formatISO(startDate),
        formatISO(endDate)
      ].join(',')
    },
    { }
  );

  if (loading) return <Loading />
  
  const questions = ids.map((id) => data[id])
  const reportData = prepareData(questions, period);
  const tableData = prepareTableData(questions);

  const isCurrentRange = (range) => {
    
    if( range === 'day' && isEqual(startDate, startOfDay(now)) && isEqual(endDate, endOfDay(now))) return activeBttonProps;
    if( range === 'week' && isEqual(startDate, startOfWeek(now)) && isEqual(endDate, endOfWeek(now))) return activeBttonProps;
    if( range === 'month' && isEqual(startDate, startOfMonth(now)) && isEqual(endDate, endOfMonth(now))) return activeBttonProps;
    if( range === 'quarter' && isEqual(startDate, startOfQuarter(now)) && isEqual(endDate, endOfQuarter(now))) return activeBttonProps;
    if( range === 'year' && isEqual(startDate, startOfYear(now)) && isEqual(endDate, endOfYear(now))) return activeBttonProps;
    
    return undefined;
  }

  const activeBttonProps = {
    color: 'primary',
    variant: 'contained'
  }

  return (
    <Box>
      <Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>

          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="MM-dd-yyyy"
            value={startDate}
            onChange={(value) => setStartDate(value) }
            inputVariant="filled"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            style={{ marginRight: 10 }}
          />

          <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="Date picker dialog"
            format="MM-dd-yyyy"
            value={endDate}
            onChange={(value) => {
              setEndDate(value);
              
            }}
            inputVariant="filled"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        
        <ButtonGroup size="large" aria-label="outlined button group" style={{ marginTop: 25, marginLeft: 10 }}>
          <Button {...isCurrentRange('day')} onClick={() => setDateRangeTo("day")}>День</Button>
          <Button {...isCurrentRange('week')} onClick={() => setDateRangeTo("week")}>Неделя</Button>
          <Button {...isCurrentRange('month')} onClick={() => setDateRangeTo("month")}>Месяц</Button>
          <Button {...isCurrentRange('quarter')} onClick={() => setDateRangeTo("quarter")}>Квартал</Button>
          <Button {...isCurrentRange('year')} onClick={() => setDateRangeTo("year")}>Год</Button>
        </ButtonGroup>
      </Box>
      <Box>
      <TableContainer component={Paper} style={{ marginBottom: 20 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Задано вопросов</TableCell>
              <TableCell>Отвечено</TableCell>
              <TableCell>Сумма</TableCell>
              <TableCell>Сумма (-3.9%)</TableCell>

            </TableRow>
          </TableHead>

          <TableBody>
            { orderBy(tableData, (r) => r.price).map((row) => (
              <TableRow>
                <TableCell>{priceLabel(row.price)}</TableCell>
                <TableCell>{row.total}</TableCell>
                <TableCell>{row.complete}</TableCell>
                <TableCell>{row.sum.toFixed(2)} руб.</TableCell>
                <TableCell>{row.sumWithoutTax.toFixed(2)} руб.</TableCell>
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow>

              <TableCell>Всего</TableCell>
              <TableCell>{sumBy(tableData, (r) => r.total)}</TableCell>
              <TableCell>{sumBy(tableData, (r) => r.complete)}</TableCell>
              <TableCell>{sumBy(tableData, (r) => r.sum)}</TableCell>
              <TableCell>{sumBy(tableData, (r) => r.sumWithoutTax).toFixed(2)} руб.</TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      </Box>
      
      <Box>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="period">Период</InputLabel>

          <Select
            labelId="Период"
            
            label="Период"
            id="period"
            name="period"
            inputProps={{
              name: 'period',
              id: 'period',
            }}
            value={period}
            
            onChange={(e) => {
              setPeriod(e.target.value)
              refresh()
            }}
            variant="filled"
          >
            <MenuItem value="day">День</MenuItem>
            <MenuItem value="week">Неделя</MenuItem>
            <MenuItem value="month">Месяц</MenuItem>
            <MenuItem value="quarter">Квартал</MenuItem>
            <MenuItem value="year">Год</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box component={Paper} width="100%" height={600}>

      <ResponsiveContainer height="100%" width="100%">
      <ComposedChart
        width={1200}
        height={600}
        data={reportData}
        margin={{
          top: 50,
          right: 30,
          left: 20,
          bottom: 50
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis yAxisId="count" dataKey="count" name="Вопросов" />
        <YAxis yAxisId="money" orientation="right" dataKey="sum" unit="руб." />
        <Tooltip />
        <Legend />

          <Bar 
            label
            name="Вопросов" 
            yAxisId="count" 
            dataKey="count" 
            barSize={8} 
            fill="#8884d8" 
          />

          <Bar 
            label
            barSize={8} 
            name="Оплачено" 
            yAxisId="count" 
            dataKey="paidCount" 
            fill="blue"
          />

          <Line 
            yAxisId="money" 
            dataKey="sum" 
            name="Сумма" 
            type="monotone" 
            strokeWidth={1}
            stroke="green"
          />
        </ComposedChart>
      </ResponsiveContainer>
      
    </Box>

    <Box>
      <ReturningUsers />
    </Box>
    </Box>

  ); 
}

export default Analytics;