import { 
  useShowController, 
  SimpleShowLayout, 
  useNotify, 
  useGetManyReference, 
  Button, 
  useRefresh, 
  fetchStart, 
  fetchEnd,
} from "react-admin";
import { useDispatch } from 'react-redux';
import React, { useState, useCallback } from "react";
import clsx from 'clsx';
import { 
  Card, 
  CardContent, 
  CardHeader, 
  CardActionArea,
  CardActions,
  Collapse,
  Avatar,
  IconButton,
  Typography, 
  TextField,
  FormControlLabel,
  Checkbox,
  Paper,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { Form, Field } from 'react-final-form';
import dayjs from 'dayjs'; 
import Dropzone from 'react-dropzone';
import accept from 'attr-accept';

const botBaseUrl = process.env.NODE_ENV === 'production' 
  ? 'https://question-telegram-bot.advaitac.com'
  // : 'http://test-telegram-bot.loca.lt';
  : 'http://localhost';

const UserMessages = (props) => {
  const {
    basePath, // deduced from the location, useful for action buttons
    defaultTitle, // the translated title based on the resource, e.g. 'Post #123'
    loaded, // boolean that is false until the record is available
    loading, // boolean that is true on mount, and false once the record was fetched
    record, // record fetched via dataProvider.getOne() based on the id from the location
    resource, // the resource name, deduced from the location. e.g. 'posts'
    version, // integer used by the refresh feature
} = useShowController(props)

  // const user = useGetOne('users', props.id);  

  const { data: messages, loading: isMessagesLoading, ids } = useGetManyReference(
    'messages', 
    'userId', 
    props.id,
    { page: 1, perPage: 100 },
    { field: 'createdAt', order: 'DESC' },
    {},
    'users',
  );

  if(!record && isMessagesLoading) return null;

  return (
    <Container>
      <SendMessage record={record} />

      { ids.map((id) => <Message message={messages[id]} key={id} />)}
    </Container>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  deleteButton: {
    color: red[500],
  }
}));

const attachmentStyle = {
  attachment: { 
    display: 'flex', 
    alignItems: 'center',
  },

  attachmentIcon: {
    marginRight: 10,
  }
}

const Message = ({ message })  => {
  const { id, text, telegramUserId, createdAt, messageData } = message;
  const isDeletable = dayjs(createdAt).add(48, 'hours') > dayjs();
  const { message_id, chat } = messageData;
  const [pending, setPending] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isDeleteConfiramtionDialogOpen, setIsDeleteConfiramtionDialogOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false)
  const deleteMessage = async () => {
    
    setPending(true);
    dispatch(fetchStart());

    await fetch(
      `${botBaseUrl}/messages/delete/`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json '
        },
        body: JSON.stringify({ 
          messageId: message_id, 
          chatId: chat.id 
        }),
      }
    );

    setPending(false);
    dispatch(fetchEnd());
    
    notify('Message successfuly created!');
    refresh();
  }

  const handleExpandClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
    <Card key={id} variant="elevation" style={{ marginBottom: 20 }}> 
      <CardActionArea>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              ?
            </Avatar>
          }
          title={
            messageData.from.first_name || telegramUserId
          }
          subheader={createdAt}
        />
        
        <CardContent>
          <Typography>
            {text}
          </Typography>
        </CardContent>

        <CardContent>
          {
            message.attachment && (
              <div>
                { message.attachment.mimetype === 'video/mp4' && (
                  <div style={attachmentStyle.attachment}>
                    <PlayCircleOutlineIcon style={attachmentStyle.attachmentIcon} />
                    <div>
                      {message.attachment.originalname}
                    </div>
                  </div>
                ) }

                { message.attachment.mimetype === 'audio/mpeg' && (
                  <div style={attachmentStyle.attachment}>
                    <AudiotrackIcon style={attachmentStyle.attachmentIcon} />
                    <span>{message.attachment.originalname}</span>
                  </div>
                )}
              </div>
            )
          }
        </CardContent>
      </CardActionArea>
      
      <CardActions disableSpacing>
        
        <Button 
          disabled={pending || !isDeletable}
          onClick={() => setIsDeleteConfiramtionDialogOpen(true)}
          size="smal"
          className={classes.deleteButton}
        >
          <span>Delete</span>
        </Button>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: isExpanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={isExpanded}
          aria-label="show more"
        > <ExpandMoreIcon /></IconButton>
      </CardActions>

      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <CardContent>
          <pre>
            {JSON.stringify(messageData, null, '\t')}
          </pre>
        </CardContent>
      </Collapse>
    </Card>

    <Dialog open={isDeleteConfiramtionDialogOpen}>
      <DialogTitle>Delete message</DialogTitle>
      <DialogContent>
        <DialogContentText>Вы действительно хотите удалить сообщение?</DialogContentText>
      </DialogContent>

      <DialogActions >
        <Button onClick={() => setIsDeleteConfiramtionDialogOpen(false)}><span>No</span></Button>
        <Button onClick={() => {
          setIsDeleteConfiramtionDialogOpen(false);
          deleteMessage();
        }}><span>Yes</span></Button>
      </DialogActions>
    </Dialog>
    </>
)};

const UserShow = props => (
  <UserMessages {...props}>
      <SimpleShowLayout>
          ...
      </SimpleShowLayout>
  </UserMessages>
)

export { UserShow }

const initialValues = {
  messageText: '',
  attachment: [],
}

const SendMessage = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [isSendConfiramtionDialogOpen, setIsSendConfiramtionDialogOpen] = useState(false);

  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      
      <Form
        initialValues={initialValues}
        onSubmit={async (values) => {

          const formData = new FormData();

          formData.append('telegramUserId', record.telegramUser);
          formData.append('telegramChatId', record.telegramUser);
          formData.append('text', values.messageText);
          
          if (values.attachment.length) {
            const attachment = values.attachment[0];
            formData.append('attachment', attachment);
            formData.append('attachment_name', attachment.name);
          }          

          setPending(true);
          dispatch(fetchStart());

          await fetch(
            `${botBaseUrl}/messages/send/`,
            {
              method: 'post',
              body: formData,
            }
          );

          dispatch(fetchEnd());
          setPending(false);
          notify('Message successfuly created!');
          refresh();

        }}
        
        validate={(values) => {
          const errors = {};
          
          if (!values.attachment[0] && values.messageText?.length < 3) {
            errors.messageText = 'Минимальная длина сообщения 3 символа';
          }

          return errors;
        }}

        render={({ handleSubmit, valid, pristine, reset, form }) => (
          <form encType="multipart/form-data">
            <Typography variant="h4" component="h4" style={{ marginBottom: 20 }}>Отправить сообщение</Typography>
            <Field name="messageText">
              {({ input, meta }) => (
                <div>
                  <TextField 
                    fullWidth 
                    multiline 
                    rows={5}
                    rowsMax={10}
                    label="Message"
                    helperText="Текст сообщения, который будет отправлено в телеграм."
                    type="text" 
                    placeholder="Write your message.." 
                    style={{ marginBottom: 20 }}
                    {...input} 
                  />
                  {meta.touched && meta.error && <span>{meta.error}</span>}
                </div>
              )}
            </Field>

            <Field name="attachment">
              {({ input }) => (
                <div>
                  <Dropzone
                    accept={[ 'video/mp4', 'audio/*', 'image/jpeg', 'image/png' ]}
                    multiple={false}
                    onDrop={(files) => {
                      form.change('attachment', files.map(file => Object.assign(file, {
                        preview: URL.createObjectURL(file)
                      })))
                    }}

                    onDropRejected={(files) => {
                      console.log('rejected')
                    }}
                  >
                    {({getRootProps, getInputProps}) => (
                      <>
                        <div {...getRootProps()} style={{ border: '2px dashed gray', padding: 20, marginTop: 20, marginBottom: 20 }}>
                          <input {...getInputProps()} />
                          <p>Drag 'n' drop some files here, or click to select files</p>
                        </div>
                        
                        { input.value[0] && <FilePreview file={input.value[0]} />}
                        
                      </>
                    )}
                  </Dropzone>
                </div>
              )}
            </Field>

            <Button 
              type="submit" 
              disabled={!valid || pristine || pending }
              onClick={(event) => {
                event.preventDefault();
                setIsSendConfiramtionDialogOpen(true);
              }}
            ><span>Отправить</span></Button>
            
            <Dialog open={isSendConfiramtionDialogOpen}>
              <DialogTitle>Send message</DialogTitle>
              <DialogContent>
                { pending && <CircularProgress size={40} style={{ marginBottom: 20 }} />}
                <DialogContentText>Вы действительно хотите отправить сообщение?</DialogContentText>
              </DialogContent>

              <DialogActions >
                <Button
                  disabled={pending} 
                  onClick={() => setIsSendConfiramtionDialogOpen(false)}><span>No</span></Button>
                <Button 
                  disabled={pending}
                  onClick={async (event) => {
                    event.preventDefault()
                    await handleSubmit(event)
                    form.reset();
                    setIsSendConfiramtionDialogOpen(false);
                }}><span>Yes</span></Button>
              </DialogActions>
            </Dialog>
          </form>

        )}
      />

      
    </Paper>
  )
}

const FilePreview = ({ file }) => {
  const {preview, name} = file;
  let Preview;

  if (accept(file, 'image/*')) {
    Preview = <img src={preview} style={{ maxWidth: 200, maxHeight: 200 }} alt={name} />;
  } else if (accept(file, ['video/*', 'audio/*'])) {
    let MediaTag;
    
    if (accept(file, 'video/*')) MediaTag = 'video';
    if (accept(file, 'audio/*')) MediaTag = 'audio';
    
    Preview = (
      <MediaTag controls width={200}>
        <source src={preview}></source>
      </MediaTag>
    )
  } else {
    Preview = <p>{name}</p>;
  }

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      {Preview}
    </div>
  )
}